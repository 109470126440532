import React, { useState } from 'react';
import s from './One.module.css';
import QuestionsBar from '../../components/QuestionsBar/QuestionsBar';
import Button from '../../components/Button/Button';
import Answer from '../../components/Answer/Answer';
import star1 from '../../images/Star1.svg';
import list from '../../images/list.svg';
import heart from '../../images/heart.svg';
import stars from '../../images/stars.svg';
import Modal from '../../components/Modals/Modal';
import ErrorModal from '../../components/Modals/ErrorModal';
import { sendData } from '../../utils/api';
import { date } from '../../utils/constants';

export default function One({ spiceId, url }) {
  const correctAnswers = [
    'Специальным предложениям',
    'Заданиям, чтобы получать дополнительные предложения',
    'Комплиментам в фирменных магазинах Q STORE',
    'Ранний доступ к сервисам и продуктам',
  ];

  const [modal, setModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [difference, setDifference] = useState([]);
  const [answered, setAnswered] = useState(false);

  const onSubmit = (e) => {
    let answers = [];
    e.preventDefault();
    const form = e.target;
    const data = new FormData(form);

    for (let key of data.keys()) {
      answers.push(key);
    }
    const sendingData = {
      question_1: answers,
      status: 'Опрос прерван',
      spice_id: spiceId,
      survey: 'Task_Education_w8',
      last_answer_time: date,
    };
    sendData(sendingData).then(() => {
      const difference = correctAnswers.filter((x) => !answers.includes(x));
      setDifference(difference);
      setAnswered(true);
      const result = answers.toString() === correctAnswers.toString();
      if (result) {
        setTimeout(() => {
          setModal(true);
        }, 1000);
      } else {
        setTimeout(() => {
          setErrorModal(true);
        }, 1000);
      }
    });
  };

  const questions = [
    { text: 'Специальным предложениям', img: star1 },
    { text: 'Заданиям, чтобы получать дополнительные предложения', img: list },
    { text: 'Комплиментам в фирменных магазинах Q STORE', img: heart },
    { text: 'Ранний доступ к сервисам и продуктам', img: stars },
  ];

  return (
    <>
      <div className={s.one}>
        <QuestionsBar questionsNumber={6} activeQuestions={1} />
        <h1 className={s.title}>
          Программа лояльности Q CLUB открывает доступ к:
        </h1>
        <form className={s.form} id="one" onSubmit={onSubmit}>
          {questions.map((item) => {
            return (
              <Answer
                text={item.text}
                img={item.img}
                key={item.text}
                difference={difference}
                answered={answered}
              />
            );
          })}

          <p className={s.text}>Нужно выбрать несколько вариантов</p>
        </form>
        <Button text="Ответить" form="one" type="submit" />
      </div>
      {modal && (
        <Modal
          title="Отличное начало!"
          text="Вы точно знаете обо всех возможностях, доступных в Q CLUB. Нам приятно видеть таких знатоков программы лояльности."
          boldText="Продолжим?"
          buttonText="Продолжим"
          nextQuestion="two"
        />
      )}
      {errorModal && (
        <ErrorModal
          title="Вы были близки!"
          text="Участники Q CLUB получают доступ к предложениям, заданиям, комплиментам, а иногда и ранний доступ к сервисам и продуктам. Все ответы были верными."
          boldText="Готовы к следующему вопросу?"
          buttonText="Готов!"
          nextQuestion="two"
        />
      )}
    </>
  );
}
