import React, { useState } from 'react';
import s from './Five.module.css';
import QuestionsBar from '../../components/QuestionsBar/QuestionsBar';
import Button from '../../components/Button/Button';
import Answer from '../../components/Answer/Answer';
import charge from '../../images/charge.svg';
import tool from '../../images/tool.svg';
import heart from '../../images/heart.svg';
import stars from '../../images/stars.svg';
import Modal from '../../components/Modals/Modal';
import ErrorModal from '../../components/Modals/ErrorModal';
import { sendData } from '../../utils/api';
import { date } from '../../utils/constants';

export default function Five({ spiceId }) {
  const correctAnswers = [
    'Освежающая вода и ароматный кофе*',
    'Подзарядка для вашего устройства',
    'Устройство во временное пользование**, если ваше осталось дома',
    'Сезонные комплименты',
  ];

  const questions = [
    { text: 'Освежающая вода и ароматный кофе*', img: heart },
    { text: 'Подзарядка для вашего устройства', img: charge },
    {
      text: 'Устройство во временное пользование**, если ваше осталось дома',
      img: tool,
    },
    { text: 'Сезонные комплименты', img: stars },
  ];

  const [modal, setModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [difference, setDifference] = useState([]);
  const [answered, setAnswered] = useState(false);

  const onSubmit = (e) => {
    let answers = [];
    e.preventDefault();
    const form = e.target;
    const data = new FormData(form);

    for (let key of data.keys()) {
      answers.push(key);
    }
    const sendingData = {
      question_5: answers,
      status: 'Опрос прерван',
      spice_id: spiceId,
      survey: 'Task_Education_w8',
      last_answer_time: date,
    };

    sendData(sendingData).then(() => {
      const difference = correctAnswers.filter((x) => !answers.includes(x));
      setDifference(difference);
      setAnswered(true);
      const result = answers.toString() === correctAnswers.toString();
      if (result) {
        setTimeout(() => {
          setModal(true);
        }, 1000);
      } else {
        setTimeout(() => {
          setErrorModal(true);
        }, 1000);
      }
    });
  };

  return (
    <>
      <div className={s.one}>
        <QuestionsBar questionsNumber={6} activeQuestions={5} />
        <h1 className={s.title}>
          А вы знаете, что ждет участников Q CLUB в фирменном магазине Q STORE?
        </h1>
        <form className={s.form} id="five" onSubmit={onSubmit}>
          {questions.map((item) => {
            return (
              <Answer
                text={item.text}
                img={item.img}
                key={item.text}
                difference={difference}
                answered={answered}
              />
            );
          })}

          <p className={s.text}>Нужно выбрать несколько вариантов</p>
        </form>
        <Button text="Ответить" form="five" type="submit" />
        <p className={s.miniText}>
          *Пока продукт есть в наличии. Кофе и вода доступны только в бутиках
        </p>
        <p className={s.miniText}>**Стоимость 10 рублей </p>
      </div>
      {modal && (
        <Modal
          title="Так точно, всё для вас!"
          text=""
          boldText="Готовы к последнему вопросу?"
          nextQuestion="six"
          buttonText="Готов!"
        />
      )}
      {errorModal && (
        <ErrorModal
          title="А вот и нет."
          text="Все ответы верны, и в наших фирменных магазинах Q STORE всё для вас."
          boldText="Готовы к последнему вопросу?"
          buttonText="Готов!"
          nextQuestion="six"
        />
      )}
    </>
  );
}
